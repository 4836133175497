import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import "./matches.css";
import MatchCard from "../components/matchCard";

const Matches = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Load and parse the CSV file
    const fetchData = async () => {
      const response = await fetch("/data/match_history.csv");
      const csvText = await response.text();

      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data;

          // Group entries by date
          const groupedByDate = parsedData.reduce((acc, entry) => {
            const date = entry.Date;
            if (!acc[date]) acc[date] = [];
            acc[date].push(entry);
            return acc;
          }, {});

          // Sort dates closest to today's date first
          const orderedData = Object.keys(groupedByDate)
            .sort((a, b) => new Date(a) - new Date(b)) // Sort dates in ascending order
            .flatMap((date) => groupedByDate[date])
            .reverse(); // Flatten grouped entries

          setData(orderedData);
        },
      });
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Match History</h1>
      <div className="matchCard-grid">
        {data.map((row, index) => (
          <MatchCard key={index} match={row} />
        ))}
      </div>
    </div>
  );
};

export default Matches;
