import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate } from "react-router-dom";
import "./bottomNav.css"

const BottomNav = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleNavigation = (newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/matches");
        break;
      case 2:
        navigate("/profile");
        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => handleNavigation(newValue)}
      showLabels
      className="navigation"
    >
      <BottomNavigationAction label="Results" icon={<LeaderboardIcon />} />
      <BottomNavigationAction label="Matches" icon={<HistoryIcon />} />
    </BottomNavigation>
  );
};

export default BottomNav;