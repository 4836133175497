import React from "react";
import PlayerList from "../components/PlayerList"
import './home.css';

const Home = () => {
    return (
        <div className="Home">
          <h1>🏆 Padel Ladder 🏆</h1>
          <div className="row">
            <div className="container">
              
            </div>
          </div>
    
          <PlayerList />
        </div>
      );
};

export default Home;
