import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import "./PlayerList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

function PlayerList() {
  const [players, setPlayers] = useState([]);
  const [previousSeasonData, setPreviousSeasonData] = useState([]);

  useEffect(() => {
    // Function to parse CSV files
    const fetchCSVData = async (url, callback) => {
      try {
        const response = await fetch(url);
        const csvText = await response.text();
        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => callback(result.data),
        });
      } catch (error) {
        console.error("Error loading the CSV file:", error);
      }
    };

    // Fetch both current and previous season data
    fetchCSVData("/data/ladderlemur.csv", (data) => {
      const sortedData = data
        .map((player) => ({
          ...player,
          ELO: parseFloat(player.ELO),
        }))
        .filter((player) => player.Games > 0)
        .sort((a, b) => b.ELO - a.ELO)
        .map((player, index) => ({
          ...player,
          Position: index + 1,
        }));
      setPlayers(sortedData);
    });

    fetchCSVData(
      "/data/sunday-scores.csv",
      (data) => {
        const sortedData = data
          .map((player) => ({
            ...player,
            ELO: parseFloat(player.ELO),
          }))
          .filter((player) => player.Games > 0)
          .sort((a, b) => b.ELO - a.ELO)
          .map((player, index) => ({
            ...player,
            Position: index + 1,
          }));
        setPreviousSeasonData(sortedData);
      }
    );
  }, []);

  // Function to determine position change
  const getPositionChange = (playerName) => {
    const normalizeName = (name) => name.trim().toLowerCase();
    const normalizedName = normalizeName(playerName);

    const previousPlayer = previousSeasonData.find(
      (prevPlayer) => normalizeName(prevPlayer.Player) === normalizedName
    );

    if (!previousPlayer) return "¬"; // Not in previous season

    const currentPlayer = players.find(
      (currentPlayer) => normalizeName(currentPlayer.Player) === normalizedName
    );

    if (!currentPlayer) return null;

    const positionChange = currentPlayer.Position - previousPlayer.Position;
    return positionChange === 0 ? "-" : positionChange;
  };

  return (
    <div className="row">
      <div className="container">
        <div className="player-list">
          <div className="row-titles">
            <div className="data desktop">
              <h3>Position</h3>
            </div>
            <div className="data mobile">
              <h3>#</h3>
            </div>
            <div className="data player">
              <h3>Player</h3>
            </div>
            <div className="data games">
              <h3>Games</h3>
            </div>
            <div className="data">
              <h3>Wins</h3>
            </div>
            <div className="data">
              <h3>Losses</h3>
            </div>
          </div>
          <div className="player-results">
            {players.map((player, index) => {
              const positionChange = getPositionChange(player.Player);
              let chevronIcon = null;
              let chevronClass = "";

              if (positionChange === "¬") {
                chevronIcon = "¬";
              } else if (positionChange === "-") {
                chevronIcon = <div className="no-change-icon">-</div>;
              } else if (positionChange > 0) {
                chevronIcon = (
                  <div class="chevron-stack">
                    <FontAwesomeIcon icon={faChevronDown} />
                    {positionChange > 10 && (
                      <FontAwesomeIcon
                        className="second-chev"
                        icon={faChevronDown}
                      />
                    )}
                  </div>
                );
                chevronClass = "down";
              } else if (positionChange < 0) {
                chevronIcon = (
                  <div class="chevron-stack">
                    <FontAwesomeIcon icon={faChevronUp} />
                    {positionChange < -10 && (
                      <FontAwesomeIcon
                        className="second-chev"
                        icon={faChevronUp}
                      />
                    )}
                  </div>
                );
                chevronClass = "up";
              }

              return (
                <div key={index} className="player-card">
                  <div className={`data rank ${chevronClass}`}>
                    {index + 1} {chevronIcon}
                  </div>
                  <div className="data name">{player.Player}</div>
                  <div className="data games">{player.Games}</div>
                  <div className="data wins">{player.Wins}</div>
                  <div className="data losses">{player.Losses}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerList;
