import React from "react";
import "./matchCard.css";

const MatchCard = ({ match }) => {
  const formatNames = (names) => {
    return names.split("&").map((name) => name.trim());
  };
  const winners = formatNames(match.Winners);
  const losers = formatNames(match.Losers);

  return (
    <div className="matchCard">
      <div className="names">
        <div class="name-style winners">
          {winners.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
        <div className="vs">vs</div>
        <div class="name-style losers">
          {losers.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
      </div>
      <div class="scores">
        <div>{match.Score1}</div>
        <div>{match.Score2}</div>
        {match.Score3 && <div>{match.Score3}</div>}
      </div>
      <div class="date">{match.Date}</div>
    </div>
  );
};

export default MatchCard;
