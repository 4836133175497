import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BottomNav from "./components/bottomNav.jsx";
import Home from "./pages/Home";
import Matches from "./pages/Matches.jsx";


const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/matches" element={<Matches />} />
        </Routes>
      </div>
      <BottomNav />
    </Router>
  );
};

export default App;
